import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private api: ApiGatewayService) { }

  impersonate(payload) {
    return this.api.post('auth/impersonate', payload);
  }

  deleteAccount(email) {
    return this.api.delete('admin/merchant', { email });
  }

  getMerchantBySlug(slug) {
    return this.api.get('admin/merchant/' +  slug);
  }

  getMerchantBySlugOrEmail(slugOrEmail) {
    return this.api.post('admin/merchant/get', { slugOrEmail });
  }

  createManualPayment(merchantId, amount, planId, quantity, vatNumber) {
    return this.api.post('admin/payment/manual', {
      amount, planId, quantity, merchantId, vatNumber
    });
  }
}
